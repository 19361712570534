import Img8 from "../images/im8.svg";
import Img12 from "../images/img12.svg";

import axios from "axios";
import { FC, useEffect, useState } from "react";
import config from "../utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
// @ts-ignore
import Fade from "react-reveal/Fade"
import Loader from "../components/Loader"
export interface aboutIntro {
  image: string;
  name: string;
  body: string;
  title: string;
  keyword: string;
  id: any;
}

export interface AllPage {
  className?: string;
}
declare global {
  interface Window {
    marked: any;
  }
}
const IntroIPO: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [about, setAbout] = useState<aboutIntro>();
  const [loading, setLoading] = useState(false);
  const sectionHomeAbout = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Home")
      .then(function (response) {
        const allData = response.data.data;
        console.log("about ", allData[1]);
        setAbout(allData[1]);
        setLoading(false);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    sectionHomeAbout();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };
  return loading ? <Loader height={900} /> : (
    <>
      {/*============= Introducing IPO  ==============*/}
      <section id="about_link" className="">
          <div className="container">
            <div className="row">
              <div className="about-area">
                <div className="ipo-image-area">
                  <img  src={ config.imageURL + "public/upload/cms/widget/" + about?.image }  alt="image"   />
                </div>
                <div className="about-area-info">
                  <Fade right duration={3000}>
                  <div className="common-para-type-two">
                    <h2>
                      <span dangerouslySetInnerHTML={createMarkup(about?.name)} />
                    </h2>
                    <p className="">
                      <span dangerouslySetInnerHTML={createMarkup(about?.body)} />
                    </p>
                  </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
      </section>


      {/*============= Introducing IPO END   ==============*/}
    </>
  );
};

export default IntroIPO;
