import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import './styles/about.css';
import './styles/carousel.css';
// import './styles/carousel.css.bak';
import './styles/cashmanagement.css';
import './styles/footer.css';
import './styles/fractional.css';
import './styles/hero.css';
import './styles/ipo.css';
import './styles/navbar.css';
import './styles/options.css';
import './styles/owl.carousel.min.css';
import './styles/products.css';
import './styles/responsive.css';
import './styles/global.css';
ReactDOM.render(

    <App />
,
  document.getElementById('root')
);

reportWebVitals();
