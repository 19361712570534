import axios from "axios";
import { FC, useEffect, useState } from "react";
import config from "../utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
// @ts-ignore
import Fade from "react-reveal/Fade"
import Loader from "../components/Loader"

export interface wwdIntro {
  image: string;
  name: string;
  body: string;
  title: string;
  keyword: string;
  id: any;
}

export interface AllPage {
  className?: string;
}
declare global {
  interface Window {
    marked: any;
  }
}

const HowWeDo: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [hwdTitle, setHwdTitle] = useState<wwdIntro>();
  const [hwdIntroOne, setHwdIntroOne] = useState<wwdIntro>();
  const [hwdIntroTwo, setHwdIntroTwo] = useState<wwdIntro>();
    const [loading, setLoading] = useState(false);
  const sectionWwd = () => {
      setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Home")
      .then(function (response) {
        const allData = response.data.data;
        console.log("wwd ", allData[3]);
        setHwdTitle(allData[5]);
        setHwdIntroOne(allData[6]);
        setHwdIntroTwo(allData[7]);
          setLoading(false);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    sectionWwd();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };
  return  loading ? <Loader height={900} /> :(
    <>
      {/*=============  Fractional area    ==============*/}
        <section id="how_link" className=" section-padding hwd__bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 justify-center">
                        {/*<div className="hwd-image-area">*/}
                        {/*    <img*/}
                        {/*        src={*/}
                        {/*            config.imageURL +*/}
                        {/*            "public/upload/cms/widget/" +*/}
                        {/*            hwdTitle?.image*/}
                        {/*        }*/}
                        {/*        alt="image"*/}
                        {/*        className="mx-auto d-block"*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="smartphone">*/}
                        {/*    <div className="content__video">*/}
                        {/*        <video width="621" height="224" poster="https://www.cambridgemaths.org/Images/The-trouble-with-graphs.jpg" controls>*/}
                        {/*            /!*<source src="https://www.youtube.com/watch?v=XMUYoiUlutM" type="video/mp4"/>*!/*/}
                        {/*        </video>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="hwd-title-area text-center mt-5">
                            <h2>   <span dangerouslySetInnerHTML={createMarkup(hwdTitle?.name)}  />  </h2>
                        </div>

                    </div>
                </div>
                <div className="row justify-center">
                    <div className="col-md-12 mt-5">
                         <div className="row">
                             <Fade left duration={3000}>
                             <div className="col-md-6">
                                 <div className="fractional-item  common-para-type-three">
                                     <h5 className="">  <span  dangerouslySetInnerHTML={createMarkup(  hwdIntroOne?.name )} /> </h5>
                                     <p className="text-justify ">   <span  dangerouslySetInnerHTML={createMarkup(   hwdIntroOne?.body  )} /> </p>
                                 </div>
                             </div>
                             </Fade>
                             <Fade right duration={3000}>
                             <div className="col-md-6">
                                 <div className="fractional-item common-para-type-two">
                                     <h5 className="">  <span  dangerouslySetInnerHTML={createMarkup(  hwdIntroTwo?.name )} /> </h5>
                                     <p className="text-justify "> <span  dangerouslySetInnerHTML={createMarkup(   hwdIntroTwo?.body  )} />  </p>
                                 </div>
                             </div>
                             </Fade>
                         </div>
                    </div>
                </div>
            </div>
        </section>
        {/*<section id="how_link" className=" section-padding hwd__bg">*/}
        {/*    <div className="container">*/}
        {/*        <div className="row">*/}
        {/*            <div className="col-md-12">*/}
        {/*                <div className="hwd-title-area">*/}
        {/*                    <h2>   <span dangerouslySetInnerHTML={createMarkup(hwdTitle?.name)}  />  </h2>*/}
        {/*                </div>*/}
        {/*                <div className="fractional-area-info">*/}

        {/*                    <div className="fractional-item common-para-type-two">*/}
        {/*                        <h5 className="">  <span  dangerouslySetInnerHTML={createMarkup(  hwdIntroOne?.name )} /> </h5>*/}
        {/*                        <p className="text-justify ">   <span  dangerouslySetInnerHTML={createMarkup(   hwdIntroOne?.body  )} /> </p>*/}
        {/*                    </div>*/}


        {/*                    <div className="fractional-item common-para-type-three">*/}
        {/*                        <h5 className="">  <span  dangerouslySetInnerHTML={createMarkup(  hwdIntroTwo?.name )} /> </h5>*/}
        {/*                        <p className="text-justify "> <span  dangerouslySetInnerHTML={createMarkup(   hwdIntroTwo?.body  )} />  </p>*/}
        {/*                    </div>*/}


        {/*                    <div className="fractional-item ">*/}
        {/*                        <div className="hwd-image-area">*/}
        {/*                            <img*/}
        {/*                                src={*/}
        {/*                                    config.imageURL +*/}
        {/*                                    "public/upload/cms/widget/" +*/}
        {/*                                    hwdTitle?.image*/}
        {/*                                }*/}
        {/*                                alt="image"*/}
        {/*                                className="img-fluid"*/}
        {/*                            />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}

        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}


      {/*=============  Fractional area END   ==============*/}
    </>
  );
};

export default HowWeDo;
