import React from 'react'
import New2 from "../images/new2.png"
// @ts-ignore
import Zoom from "react-reveal/Zoom";
const ProductsArea = () => {
    return (
        <>
            {/*=============  Our Products  area    ==============*/}
            <section id="product" className="section-padding">
                <div className="container" >

                    <div className="row">
                        {/*<div className="col-md-4 my-auto col-12">*/}
                        {/*    <div className="product-image">*/}
                        {/*        <img className="img-fluid mx-auto d-block" src={New2} alt="" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <Zoom center duration={3000}>
                        <div className="col-md-12  mx-auto d-flex" >
                            <div className="common-para-type-two">
                                <h2>Our Products</h2>
                                <p className="">
                                    Get mobile access to the markets. Invest commission-free in
                                    individual companies or bundles of investments (ETFs).
                                    Get mobile access to the markets. Invest commission-free in
                                    individual companies or bundles of investments (ETFs).
                                    Get mobile access to the markets. Invest commission-free in
                                    individual companies or bundles of investments (ETFs).
                                </p>
                                <div className="custom-btn-area mt-5 mx-auto d-flex text-center">
                                    <a className="custom-btn color-secondaryone" href="#">
                                        Learn about Stocks & Funds</a
                                    >
                                </div>
                            </div>
                        </div>
                        </Zoom>
                    </div>
                </div>
            </section>
            {/*=============  Our Products  area END   ==============*/}
        </>
    )
}

export default ProductsArea;
