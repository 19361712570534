import React from 'react';
import '../styles/additional.css'

const TermsAndConditions = () => {
    return (
         <>
             {/*style={{height:'50vh', backgroundImage:*/}
             {/*    "url('https://snjobs24.com/wp-content/uploads/2021/02/termsandcondition.png')",  backgroundSize: 'cover',*/}
             {/*backgroundRepeat: 'no-repeat'}}*/}

        <div className="d-flex container-fluid terms-and-condition-bg">
        </div>

        <div className="container p-5 bg-light" style={{marginTop:'-100px'}}>
            <div className="row">
                <div className="col-md-4 text-center align-self-center">
                    <div className="lc-block border-end border-2 ">
                        <div >
                            <p className="display-4 text-secondary title_side_text">WEALTHLINK?</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10 mx-auto">
                    <div className="lc-block ">
                        <div >
                            <p className="display-4 title_text">Wealth Link Terms Of Use</p>
                        </div>
                    </div>
                </div>
            </div>


            	<div className="row mt-5">

                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                            <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">OVERVIEW</span></p>
                                    <p className="item-menu-desc  justifyText" >WEALTH LINK LLC is a New Jersey limited liability company (“WEALTH LINK Spend”) with office at 2816 Morris Ave Ste 38, Union, New Jersey 07083 (referred to collectively herein as “WEALTH LINK,” “We,” “Us,” or “Our”).

                                        By using or downloading our mobile application (the “WEALTH LINK Mobile App”) or accessing or using our website at www.WEALTHLINKGLOBAL.com and its subdomains (“Website”) (collectively, the “WEALTH LINK Platform”), you indicate your unconditional acceptance of the following Terms of Use (these “Terms”) on your own behalf and on behalf of any organization you represent (collectively, “you” or “your”). If you do not agree to these Terms (or any updates to or modified versions thereof), you should discontinue use of the WEALTH LINK Platform immediately.

                                        Your use of the WEALTH LINK Platform is governed by the version of the Terms in effect on the date each WEALTH LINK Platform is accessed by you. WEALTH LINK may modify these Terms at any time and without prior notice. You should review the most current version of these Terms by visiting the WEALTH LINK Platform and clicking on the Terms of Use hyperlink. These Terms are in addition to any other agreements between you and WEALTH LINK, including any customer or account agreements, and any other agreements that govern your use of information, content, tools, products, and services available on and through the WEALTH LINK Platform.
                                        <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                            <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">MEANS OF ACCESS</span></p>
                                    <p className="item-menu-desc justifyText" >The Website is generally intended to be viewed by a conventional web browser with a screen resolution of 800 by 600 pixels or greater. The WEALTH LINK Mobile Apps are meant to be viewed by a mobile phone with an Android or iOS operating system. Although you may use other means to access the WEALTH LINK Platform, be aware that the WEALTH LINK Platform may not appear accurately through other access methods, and you use them only at your own risk.

                                    You should not access the WEALTH LINK Platform through devices or services that are designed to provide high-speed, automated, repeated access, unless such devices are approved or made available by WEALTH LINK.

                                    Certain parts of the WEALTH LINK Platform are protected by passwords or require a login. You may not obtain or attempt to obtain unauthorized access to such parts of the WEALTH LINK Platform, or to any other protected materials or information, through any means not intentionally made available by WEALTH LINK for your specific use.

                                    You understand that to receive electronic deliveries, you must have internet access, a valid e-mail address, the ability to download and have ongoing access to applications as WEALTH LINK may specify and a printer or other device to download and print or save any information you may wish to retain.

                                        <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">SITE AVAILABILITY</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    While we have put resources into building and testing our computer systems, computer glitches, slowdowns, and crashes will occur. We will also need to restrict access to some parts of our website or our entire website to perform routine maintenance. We will try to schedule our maintenance during the middle of the night.

                                    While it is our intention that our website will be available seven days a week except when maintenance is scheduled (usually for weekends), you understand that we do not guarantee that you will always be able to access the WEALTH LINK Platform. Computer problems can arise on your end, our end, or anywhere in between: your computer may break down; the connection between your computer and your Internet service provider may not work properly; your Internet service provider may go down; or our computers and the computers we link to may be unavailable due to unforeseen system outages.

                                    When trading volumes soar on our nation’s stock markets and many investors want to buy or sell at the same time, lines form and orders cannot be filled as quickly. You agree that we are not responsible for any losses or liabilities that may occur as a result of high trading volume, market volatility, or computer, telecommunications, or Internet failures, regardless of the cause.

                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">PERMANENT U.S. RESIDENTS</span></p>
                                    <p className="item-menu-desc justifyText" >You must be at least 18 years of age to become a user of the WEALTH LINK Platform. You represent and warrant that you are 18 years of age or older and are fully able and competent to enter into and abide by the contract created by these Terms of Use.

                                    The WEALTH LINK Platform is for U.S. Permanent Residents and U.S. citizens residing in the U.S. only. You must be a legal Permanent Resident of the United States or a United States citizen residing in the United States in order to use the WEALTH LINK Platform.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">USE OF WEALTH LINK PLATFORM</span></p>
                                    <p className="item-menu-desc justifyText" >The WEALTH LINK Platform is intended only for your personal, non-commercial use, unless you and WEALTH LINK have agreed otherwise in writing.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">PROHIBITED USES</span></p>
                                    <p className="item-menu-desc justifyText" >Because all servers have limited capacity and are used by many people, do not use the WEALTH LINK Platform in any manner that could damage or overburden any WEALTH LINK server, or any network connected to any WEALTH LINK server. Do not use the WEALTH LINK Platform in any manner that would interfere with any other party’s use of the WEALTH LINK Platform.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">PASSWORD SECURITY AND NOTIFICATION</span></p>
                                    <p className="item-menu-desc justifyText" >If you have a password for access to non-public areas of the WEALTH LINK Platform, you are solely responsible for maintaining the confidentiality and use of the password and other security data, methods and devices. Further, you are responsible for all activities that occur in connection with your password including all instructions electronically transmitted or use of any data, information or services obtained using your password and other security data. WEALTH LINK shall not be under any duty to inquire as to the authority or propriety of any instructions given to WEALTH LINK by you or via your password and shall be entitled to act upon any such instructions and WEALTH LINK will not be liable for any loss, cost, expense or other liability arising out of any such instructions.

                                    Accordingly, you should take steps to protect the confidentiality of your password. As an authorized user you accept full responsibility for the monitoring of your account including frequently checking your account information, reviewing your transaction history online and promptly reviewing any correspondence, account statements and confirmations received from WEALTH LINK. Notify WEALTH LINK immediately if you become aware of any unauthorized activity, disclosure, loss, theft or unauthorized use of your password. You agree to cooperate with WEALTH LINK in any investigation and agree to take corrective measures to protect your account from further fraudulent activity.

                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">NETWORK SECURITY AND RELIABILITY</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    You acknowledge that the Internet is not a secure network and that communications transmitted over the Internet may be accessed by unauthorized or unintended third parties. E-mail notifications sent by WEALTH LINK will not contain sensitive or confidential information. Due to security risks, you should not send any sensitive information, such as account numbers or passwords in an unencrypted e-mail. E-mails may fail to transmit properly. Regardless of whether you receive an e-mail notification, you agree to check the WEALTH LINK website to avoid missing time-sensitive information. You further agree that for your records, you can download and save or print the communications received via electronic delivery.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">CONSENT TO ELECTRONIC DELIVERY</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    By providing WEALTH LINK with your e-mail address, you agree to receive all required notices electronically to that e-mail address. It is your responsibility to update or change the e-mail address registered with WEALTH LINK, as appropriate. If you become aware of any unauthorized use of your information, please contact WEALTH LINK.

                                    Further, by registering with WEALTH LINK you are aware and consent that electronic signatures and electronic documents will be used instead of paper documents. You agree and are giving consent to electronic delivery of all communications which includes, but is not limited to, all current and future account statements, trade confirmations, notices, disclosures, regulatory communications (including prospectuses, proxy solicitations and privacy notices) and other information, documents, data and records regarding your account all services provided by WEALTH LINK delivered or provided to you by WEALTH LINK.

                                    Your consent will be effective immediately and will remain in effect until either WEALTH LINK or you revoke it. You understand that it may take up to three days to process a revocation of consent to electronic communications and you may receive electronic notifications in the interim.

                                    You may revoke or restrict consent to electronic delivery of WEALTH LINK communications at any time by notifying WEALTH LINK. You have the right to request paper delivery of any communication that the law requires WEALTH LINK to provide in paper form. If you revoke or restrict consent to electronic delivery, WEALTH LINK, at its discretion, may charge a service fee of up to $100.00 for the delivery of communications that would otherwise be delivered electronically, restrict your account, or close your account and terminate access to the WEALTH LINK Platform.

                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">NO RECOMMENDATIONS OR INVESTMENT ADVICE</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    You understand that WEALTH LINK, through the WEALTH LINK Platform or any interaction you have with representatives of the firm, provides no tax, legal, estate planning, or investment advice of any kind, nor do we give advice or offer any opinion with respect to the nature, potential value or suitability of any particular securities transaction or investment strategy. You understand that you are solely responsible for all investment decisions you make regarding the transactions made in your account. You further understand that while you may be able to access market data and other financial information from the WEALTH LINK Platform, the availability of such information does not constitute a recommendation to buy or sell any of the securities made available for purchase on the website (including securities appearing in any portfolios published by WEALTH LINK) or to engage in any investment strategy. Any investment decisions you make will be based solely on your own evaluation of your financial circumstances and investment objectives and the suitability for you of any security or any investment strategy. Any order entered using your password is yours and you are responsible for any outcome as a result of transactions that you initiate or that is initiated by any user of your account, including the possible loss of principal invested based on an investment decision you made. You understand the risks involved with transacting in the securities you maintain and that your investments will fluctuate in value, and you agree that WEALTH LINK is not responsible for any losses you may incur as a result of your investment decisions and any trades made for your account.

                                    By making information available to you on the website, WEALTH LINK is not advising you to invest in any particular security or securities, or to pursue any investment strategy. Although WEALTH LINK may provide tools that enable you to assess your own tolerance for risk, or otherwise assist to educate you in various ways, WEALTH LINK does not determine if the tools and resources made available on the website will result in suitable investments designed to meet your particular investment needs. All investments have risks, and you are responsible for determining whether you can afford the risks of making any investment.

                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">INFORMATION SUBJECT TO LIMITATIONS</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    The information and content available on or posted to the website may not be timely or complete, and should not be relied upon for making any investment or other decisions. Information, such as stock prices, is not real-time and the past performance results of a security available for purchase through the website is not an indication of future performance. Share prices used to value individual positions, or contribute to other performance information, are provided by third-party data providers and may not be timely and may not reflect certain activities such as corporate actions, fees and commissions. WEALTH LINK cannot guarantee the accuracy or completeness of the information it receives from third-party sources of information and makes available on the website. WEALTH LINK may cause pricing, performance or other information to be unavailable with respect to a particular security from time to time if WEALTH LINK determines that such information is inaccurate.

                                    WEALTH LINK makes available hyperlinks from the website to third-party websites, as a convenience to users with the intention of allowing users to access additional investment-related information. You agree to access any such third-party websites through those hyperlinks at your own risk and to follow any rules and restrictions particular to those websites. WEALTH LINK is not responsible for the content appearing on third- party websites hyperlinked from the website, nor does WEALTH LINK make any endorsements or warranties, express or implied, with respect to the content of third-party websites, including the information, products or services offered on any third-party websites, including but not limited to accuracy, completeness, reliability, suitability, non-infringement, merchantability, or fitness for a particular purpose. WEALTH LINK does not guarantee the authenticity of information or documents available on the internet and reserves the right to terminate any link from the website at any time.

                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">TIMELINESS OF CONTENT</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    All content on the WEALTH LINK Platform is presented only as of the date published or indicated and may be superseded by subsequent market events or for other reasons. In addition, you are responsible for setting the cache settings on your browser to ensure you are receiving the most recent data.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>







                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">BROKER-DEALER’S POTENTIAL CONFLICTS</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    As a broker-dealer, WEALTH LINK Finance LLC or its affiliates may act as principal for its own account or as agent for its customers in connection with the sale to you or purchase by you of a security that is the subject of content prepared by WEALTH LINK. If it receives a mark-up or commission or acts as agent for another person in connection with any such transaction, WEALTH LINK may have a potential conflict of interest. You understand this potential conflict and acknowledge that you may choose to effect securities transactions at another broker-dealer.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">DISCLAIMER OF WARRANTIES</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    WEALTH LINK DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES ABOUT THE WEALTH LINK PLATFORM, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE WEALTH LINK PLATFORM IS MADE AVAILABLE TO YOU “AS IS” AND “AS AVAILABLE” AND WEALTH LINK DOES NOT WARRANT THAT ANY DEFECTS OR INACCURACIES WILL BE CORRECTED.

                                    WEALTH LINK DOES NOT WARRANT THAT THE WEALTH LINK PLATFORM WILL MEET YOUR NEEDS, OR THAT THEY WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. WEALTH LINK ALSO MAKES NO WARRANTY THAT THE RESULTS OBTAINED FROM THE USE OF THE WEALTH LINK PLATFORM WILL BE ACCURATE OR RELIABLE, OR THAT THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE WEALTH LINK PLATFORM WILL MEET YOUR EXPECTATIONS.

                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">LIMITATION OF LIABILITY</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    WEALTH LINK WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY CONSEQUENTIAL, INCIDENTAL, SPECIAL, DIRECT, OR INDIRECT DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS, TRADING LOSSES OR DAMAGES THAT RESULT FROM USE OR LOSS OF USE OF THE WEALTH LINK PLATOFRM AND THIRD PARTY CONTENT, INCONVENIENCE OR DELAY). THIS IS TRUE EVEN IF WEALTH LINK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.

                                    WEALTH LINK WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS RESULTING FROM A CAUSE OVER WHICH SUCH WEALTH LINK DOES NOT HAVE DIRECT CONTROL. THIS INCLUDES FAILURE OF ELECTRONIC OR MECHANICAL EQUIPMENT OR COMMUNICATIONS LINES (INCLUDING TELEPHONE, CABLE AND INTERNET), UNAUTHORIZED ACCESS, VIRUSES, THEFT, OPERATOR ERRORS, SEVERE OR EXTRAORDINARY WEATHER (INCLUDING FLOOD, EARTHQUAKE, OR OTHER ACT OF GOD), FIRE, WAR, INSURRECTION, TERRORIST ACT, RIOT, LABOR DISPUTE AND OTHER LABOR PROBLEMS, ACCIDENT, EMERGENCY OR ACTION OF GOVERNMENT.

                                    IF YOU LIVE IN A STATE THAT DOES NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SOME OR ALL OF THESE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.


                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">INDEMNIFICATION</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    As a condition of your use of the WEALTH LINK Platform, you agree to indemnify and hold WEALTH LINK and its Third Party Content providers harmless from and against any and all claims, losses, liability, costs and expenses (including but not limited to attorneys’ fees) arising from your use of the WEALTH LINK Platform, or from your violation of these Terms.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">CHANGES TO WEALTH LINK PLATFORM</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    Unless otherwise agreed, WEALTH LINK may discontinue or modify the WEALTH LINK Platform at any time without prior notice to you, and you accept those modifications if you continue to use the WEALTH LINK Platform.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">TERMINATION</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    WEALTH LINK may terminate your access to the WEALTH LINK Platform for any reason, without prior notice.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">GOVERNING LAW</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    Unless otherwise agreed, these Terms and their enforcement are governed by the laws of Union, New Jersey, without regard to conflicts of law, and shall inure to the benefit of WEALTH LINK’s successors and assigns, whether by merger, consolidation, or otherwise.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-10 mx-auto">
                        <div className="lc-block">
                        <div className="item-menu">
                                <div className="item-menu-content">
                                    <p className="item-menu-title">
                                        <span  className="">SEVERABILITY</span></p>
                                    <p className="item-menu-desc justifyText" >
                                    If a court of competent jurisdiction deems any provision unenforceable, that provision will be enforced to the maximum extent permissible, and the remaining provisions will remain in full force and effect.
                                    <br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
        </div>
          
        </>
    );
};

export default TermsAndConditions;
