import React, { FC } from 'react';
import loader from '../images/loader.gif'
const Loader = (props: { height?: number }) => {
    return (
        <div className='loader__area' style={{ minHeight: `${props.height}px` }}>
            <img src={loader} alt="Loading..." style={{width: '400px', height: '80px'}}  />
        </div>
    );
};

export default Loader;