import React, {useState} from 'react';
import "./Tabs/App.css"
import {motion} from "framer-motion";
// @ts-ignore
import Zoom from 'react-reveal/Zoom';
import NewImg from "../images/2_screens.png"
import NewImg1 from '../images/2_screen.png'
import NewImg3 from '../images/2_screen_002.png'
import NewImg2 from "../images/3 phones.png"

const NewCarousel = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = ({index}: { index: any }) => {
        setToggleState(index);
    };
    return (
        <>
            <section className="carousel__total__area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="carousel_area">
                                <div className="carousel_menu_area carousel_item">
                                    <ul>
                                        <Zoom left exit duration={2500}>
                                            <li><button className={toggleState === 1 ? "button active" : "button"}
                                                        onClick={() => toggleTab({index: 1})}>Learn</button></li>
                                        </Zoom>
                                        <Zoom left duration={3500}>
                                            <li><button className={toggleState === 2 ? "button active" : "button"}
                                                        onClick={() => toggleTab({index: 2})}>Manage</button></li>
                                        </Zoom>
                                        <Zoom left duration={5000}>
                                            <li><button className={toggleState === 3 ? "button active" : "button"}
                                                        onClick={() => toggleTab({index: 3})}>Deposit</button></li>
                                        </Zoom>
                                        <Zoom left duration={6000}>
                                            <li><button className={toggleState === 4 ? "button active" : "button"}
                                                        onClick={() => toggleTab({index: 4})}>Invest</button></li>
                                        </Zoom>
                                    </ul>
                                </div>

                                <div className="carousel_item_image_area carousel_item">
                                    <Zoom center duration={2600}>
                                        <img src={NewImg} alt=""  className={toggleState ===1 ? "image active-image" : "image"}/>
                                    </Zoom>
                                    <Zoom center duration={2600}>
                                        <img src={NewImg1} alt=""  className={toggleState ===2 ? "image active-image" : "image"}/>
                                    </Zoom>
                                    <Zoom center duration={2600}>
                                        <img src={NewImg2} alt=""  className={toggleState ===3 ? "image active-image" : "image"}/>
                                    </Zoom>
                                    <Zoom center duration={2600}>
                                        <img src={NewImg3} alt=""  className={toggleState ===4 ? "image active-image" : "image"}/>
                                    </Zoom>
                                </div>


                                <div className={toggleState ===1 ? "content carousel_item active-content marginTop" : "content "}>
                                    <Zoom center duration={2600}>
                                        <div className="carousel_item_info_title">Learn About  Your Portfolio</div>
                                    </Zoom>
                                    <Zoom center duration={2800}>
                                        <div className="carousel_item_info_des">
                                            Learning is the process of acquiring new understanding, knowledge, behaviors,
                                            skills, values, attitudes, and preferences. The ability to learn is possessed
                                            by humans, animals, and some machines; there is also evidence for some kind of
                                            learning in certain plants.
                                        </div>
                                    </Zoom>
                                </div>

                                <div className={toggleState ===2 ? "content active-content carousel_item marginTop" : "content "}>
                                    <Zoom center duration={2000}>
                                        <div className="carousel_item_info_title">Manage Your Portfolio</div>
                                    </Zoom>
                                    <Zoom center duration={2800}>
                                        <div className="carousel_item_info_des">
                                            Manageing is the process of acquiring new understanding, knowledge, behaviors,
                                            skills, values, attitudes, and preferences. The ability to learn is possessed
                                            by humans, animals, and some machines; there is also evidence for some kind of
                                            learning in certain plants.
                                        </div>
                                    </Zoom>
                                </div>
                                <div className={toggleState ===3 ? "content active-content carousel_item marginTop" : "content "}>
                                    <Zoom center duration={2000}>
                                        <div className="carousel_item_info_title">Deposit To Start</div>
                                    </Zoom>
                                    <Zoom center duration={2800}>
                                        <div className="carousel_item_info_des">
                                            A deposit, broadly speaking, represents money held at a bank.
                                            One definition of deposit refers to when a portion of funds is used as security or collateral for the delivery of goods or services.
                                            Another kind of deposit involves a transfer of funds to another party, such as a bank, for safekeeping.
                                        </div>
                                    </Zoom>
                                </div>
                                <div className={toggleState ===4 ? "content active-content carousel_item marginTop" : "content "}>
                                    <Zoom center duration={2600}>
                                        <div className="carousel_item_info_title">Invest To build</div>
                                    </Zoom>
                                    <Zoom center duration={2800}>
                                        <div className="carousel_item_info_des">
                                            A profit is the amount of money you earn from a certain product or service, which is determined by subtracting costs from sales and setting the profit margin.
                                            <br/>
                                            Making profit requires acquiring new understanding, knowledge, behaviors,
                                            skills, values, attitudes, and preferences. The ability to learn is possessed
                                            by humans, animals, and some machines; there is also evidence for some kind of
                                            learning in certain plants.
                                        </div>
                                    </Zoom>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






























            {/*<section className="carousel__total__area">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-12">*/}
            {/*             <div className="carousel_area">*/}
            {/*                 <div className="carousel_menu_area carousel_item">*/}
            {/*                     <ul>*/}
            {/*                         <Zoom left exit duration={2500}>*/}
            {/*                         <li><button className={toggleState === 1 ? "button active" : "button"}*/}
            {/*                                     onClick={() => toggleTab({index: 1})}>Learn</button></li>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom left duration={3500}>*/}
            {/*                         <li><button className={toggleState === 2 ? "button active" : "button"}*/}
            {/*                                      onClick={() => toggleTab({index: 2})}>Manage</button></li>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom left duration={5000}>*/}
            {/*                             <li><button className={toggleState === 3 ? "button active" : "button"}*/}
            {/*                                          onClick={() => toggleTab({index: 3})}>Customize</button></li>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom left duration={6000}>*/}
            {/*                             <li><button className={toggleState === 4 ? "button active" : "button"}*/}
            {/*                                         onClick={() => toggleTab({index: 4})}>Profit</button></li>*/}
            {/*                         </Zoom>*/}
            {/*                     </ul>*/}
            {/*                 </div>*/}

            {/*                 <div className="carousel_item_image_area carousel_item">*/}
            {/*                     <Zoom center duration={2600}>*/}
            {/*                     <img src={NewImg} alt=""  className={toggleState ===1 ? "image active-image" : "image"}/>*/}
            {/*                     </Zoom>*/}
            {/*                     <Zoom center duration={2600}>*/}
            {/*                     <img src={NewImg} alt=""  className={toggleState ===2 ? "image active-image" : "image"}/>*/}
            {/*                     </Zoom>*/}
            {/*                     <Zoom center duration={2600}>*/}
            {/*                         <img src={NewImg} alt=""  className={toggleState ===3 ? "image active-image" : "image"}/>*/}
            {/*                     </Zoom>*/}
            {/*                     <Zoom center duration={2600}>*/}
            {/*                         <img src={NewImg} alt=""  className={toggleState ===4 ? "image active-image" : "image"}/>*/}
            {/*                     </Zoom>*/}
            {/*                     </div>*/}


            {/*                     <div className={toggleState ===1 ? "content carousel_item active-content" : "content carousel_item"}>*/}
            {/*                         <Zoom center duration={2600}>*/}
            {/*                         <div className="carousel_item_info_title">Learn About  Your Portfolio</div>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom center duration={2800}>*/}
            {/*                         <div className="carousel_item_info_des">*/}
            {/*                             Learning is the process of acquiring new understanding, knowledge, behaviors,*/}
            {/*                             skills, values, attitudes, and preferences. The ability to learn is possessed*/}
            {/*                             by humans, animals, and some machines; there is also evidence for some kind of*/}
            {/*                             learning in certain plants.*/}
            {/*                         </div>*/}
            {/*                         </Zoom>*/}
            {/*                     </div>*/}

            {/*                     <div className={toggleState ===2 ? "content active-content carousel_item" : "content "}>*/}
            {/*                         <Zoom center duration={2000}>*/}
            {/*                         <div className="carousel_item_info_title">Manage Your Portfolio</div>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom center duration={2800}>*/}
            {/*                         <div className="carousel_item_info_des">*/}
            {/*                             Manageing is the process of acquiring new understanding, knowledge, behaviors,*/}
            {/*                             skills, values, attitudes, and preferences. The ability to learn is possessed*/}
            {/*                             by humans, animals, and some machines; there is also evidence for some kind of*/}
            {/*                             learning in certain plants.*/}
            {/*                         </div>*/}
            {/*                         </Zoom>*/}
            {/*                     </div>*/}
            {/*                     <div className={toggleState ===3 ? "content active-content carousel_item" : "content "}>*/}
            {/*                         <Zoom center duration={2000}>*/}
            {/*                         <div className="carousel_item_info_title">Customize Your Portfolio</div>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom center duration={2800}>*/}
            {/*                         <div className="carousel_item_info_des">*/}
            {/*                             Customizing is the process of acquiring new understanding, knowledge, behaviors,*/}
            {/*                             skills, values, attitudes, and preferences. The ability to learn is possessed*/}
            {/*                             by humans, animals, and some machines; there is also evidence for some kind of*/}
            {/*                             learning in certain plants.*/}
            {/*                         </div>*/}
            {/*                         </Zoom>*/}
            {/*                     </div>*/}
            {/*                     <div className={toggleState ===4 ? "content active-content carousel_item" : "content "}>*/}
            {/*                         <Zoom center duration={2600}>*/}
            {/*                         <div className="carousel_item_info_title">Make Profit</div>*/}
            {/*                         </Zoom>*/}
            {/*                         <Zoom center duration={2800}>*/}
            {/*                         <div className="carousel_item_info_des">*/}
            {/*                             Making profit requires acquiring new understanding, knowledge, behaviors,*/}
            {/*                             skills, values, attitudes, and preferences. The ability to learn is possessed*/}
            {/*                             by humans, animals, and some machines; there is also evidence for some kind of*/}
            {/*                             learning in certain plants.*/}
            {/*                         </div>*/}
            {/*                         </Zoom>*/}
            {/*                     </div>*/}
            {/*             </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    );
};

export default NewCarousel;
