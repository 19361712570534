import React from 'react'
// import { Link } from 'react-router-dom';
import Logo from "../images/logo.svg"
// @ts-ignore
import { Link } from "react-scroll"

const Navbar = () => {
    return (
        // @ts-ignore
        <>
            {/*============= NAVBAR  START ==============*/}
            <nav className="navbar navbar-expand-lg sticky-top  navbar-light bg-light shadow">
                <div className="container">
                    <Link to="home_link"  smooth={true} offset={-100} duration={500} className="navbar-brand" > <img src={Logo} alt="" className="d-inline-block align-text-top" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="main_nav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="home_link"  smooth={true} offset={-100} duration={500} className="nav-link">Home</Link>
                            </li>
                  
                            <li className="nav-item">
                                <Link to="about_link"  smooth={true} offset={-100} duration={500} className="nav-link">About Us</Link>
                            </li>
                            <li className="nav-item dropdown has-megamenu">
                                <Link to="what_link"  smooth={true} offset={-100} duration={500} className="nav-link">What We Do?</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="how_link"  smooth={true} offset={-50} duration={500} className="nav-link">How We Do?</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="support_link"  smooth={true} offset={-100} duration={500} className="nav-link">Support</Link>
                            </li>

                        </ul>
                        <div className="ms-auto custom-btn-area navbar">
                            <a className="custom-btn color-secondaryone  mr-3" href="https://wealthlinkportal.cisstaging.com/signin"> Login</a>
                            <a className="custom-btn color-secondary ml-3" href="https://wealthlinkportal.cisstaging.com/signup"> Get Started</a>
                        </div>
                    </div>
                </div>
            </nav>
            {/*============= NAVBAR    END ==============*/}
        </>
    )
}

export default Navbar;