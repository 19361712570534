import React,{useState} from 'react';
import { BsFillChatRightQuoteFill ,BsPhoneVibrate} from "react-icons/bs"; 
import { MdOutlineMarkEmailRead ,MdOutlinePlace} from "react-icons/md";
import NewImg from "../images/cust3.png"
// import {Simulate} from "react-dom/test-utils";
// import input = Simulate.input;
const Support = () => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [inputs, setInputs] = useState({
        name: '',
        contact: '',
        email: '',
        message: ''
    });

    const handleChange = (event:any) => {
        const values = {
            ...inputs,
            [event.target.name] : event.target.value
        }
        setInputs(values)
    }

    const handleSubmit = (event:any) => {
        event.preventDefault();
        // console.log(inputs);
        if(inputs.name && inputs.email && inputs.contact){
            setIsSubmit(true);
            setTimeout(()=>{
                setIsSubmit(false)
            },3000)
        }else {
            setIsSubmit(false)
        }
        setInputs({
            name: '',
            contact: '',
            email: '',
            message: ''
        })
    }
    return (


        <div>
            <section className='support__area product-area section-padding' id="support_link">
                <div className='container'>

                    <div className='row'>
                        <div className='support__title'>Support</div>
                        {isSubmit && <span className="form__submit__mess"> Form Submit Successfully</span>}
                    <div className="col-md-5 my-auto col-12">
				       <div className="lc-block ">
					<form onSubmit={handleSubmit}>
                    {/*<div className='support__title'>Support</div>       */}
						<div className="form-group mb-4">
							<label className='label__item'>Your Name </label>
							<input name="name" type="text" className="form-control round__form" placeholder="Your Name" value={inputs.name || ""}   onChange={handleChange}/>
						</div>

						<div className="form-group mb-4">
							<label className='label__item'>Contact Number</label>
							<input name="contact" type="text" className="form-control round__form" placeholder="Contact Number" value={inputs.contact || ""}   onChange={handleChange}/>
						</div>

						<div className="form-group mb-4">
							<label className='label__item'>Your Email Address</label>
							<input name="email" type="email" className="form-control round__form" placeholder="name@example.com" value={inputs.email || ""}   onChange={handleChange}/>
						</div>



						<div className="form-group mb-4">
							<label className='label__item'>Your Message</label>
							<textarea name="message" className="form-control round__form"  value={inputs.message || ""}   onChange={handleChange}></textarea>
						</div>

						<button type="submit" className="support__btn round__form"> Submit </button>

						
					</form>
				</div>
			</div>


                        <div className='col-md-7 col-12'>
                            <div className='support__info__area'>
                                <div className='support__item__image'>
                                    <img src={NewImg} alt="image" />
                                </div>
                                <div className='support__item'>
                                    <MdOutlineMarkEmailRead /> 

                                    <p><a  href="mailto:info@wealthlinkglobal.com" target="_blank" rel="noopener noreferrer">info@wealthlinkglobal.com</a></p>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </div>
    );
};

export default Support;