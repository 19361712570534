// @ts-ignore
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutube,
} from "react-icons/fa";
//@ts-ignore
import PrivacyPolicy from '../pdf/privacypolicy.pdf';
//@ts-ignore
import Terms from '../pdf/terms.pdf';
import React from "react";
// @ts-ignore
import { Link } from "react-scroll"

const Footer = () => {

    return (
        <>
            {/*=============  Footer area    ==============*/}
            <section id="footer" className="footer-area">
                <div  className="container" style={{paddingTop:"3%"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="footer-link">
                                <li><Link to="about_link"  smooth={true} offset={-100} duration={500} className="nav-link">About</Link></li>
                                <li><a href=""> /</a></li>
                                <li><a href={PrivacyPolicy} target='_blank' rel="noreferrer"> Privacy & Policy</a></li>
                                <li><a href=""> /</a></li>
                                <li><a href={Terms} target='_blank' rel="noreferrer">Terms</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="footer-social">
                                <li className="social-icon"><a href=""><FaFacebookSquare size={30} /> </a></li>
                                <li className="social-icon"><a href=""><FaLinkedin size={30} /> </a></li>
                                <li className="social-icon"><a href=""><FaYoutube size={30} /> </a></li>
                                <li className="social-icon"><a href=""><FaTwitterSquare size={30} /> </a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-white copy__right">
                              <p>  © WealthLink Inc. {(new Date().getFullYear())} |  All rights reserved.</p>
                        </div>
                    </div>

                </div>

            </section>
            {/*=============  Footer area END   ==============*/}
        </>
    )
}

export default Footer;
