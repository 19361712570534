import axios from "axios";
import { FC, useEffect, useState } from "react";
import config from "../utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
// @ts-ignore
import Fade from "react-reveal/Fade"
import Loader from "../components/Loader"
export interface heroIntro {
  image: string;
  name: string;
  body: string;
  title: string;
  keyword: string;
  id: any;
}

export interface AllPage {
  className?: string;
}
declare global {
  interface Window {
    marked: any;
  }
}
const Hero: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [hero, setHero] = useState<heroIntro>();
  const [loading, setLoading] = useState(false);
  const sectionHomeHero = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?keyword=what_we_do")
      .then(function (response) {
        const allData = response.data.data;
        console.log("Questions_Section ", allData[0]);
        setHero(allData[0]);
        setLoading(false);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    sectionHomeHero();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };
  return loading ? <Loader height={900} /> : (
    <>
      {/*============= HERO SECTION  START   ==============*/}
      <section id="home_link" className="hero-area">
        <div className="container" style={{ paddingTop: "8%" }}>
          <div className="row hero-top">
            <div className="col-md-6 col-12 ">
              <div className="hero-text-area">
                <div className="hero-title">
                  <span dangerouslySetInnerHTML={createMarkup(hero?.name)} />
                </div>
                <p className="hero-text">
                  <span dangerouslySetInnerHTML={createMarkup(hero?.body)} />
                </p>
                <div className="custom-btn-area  mobile-btn  web-btn">
                  <a className="custom-btn color-secondaryone " href="https://wealthlinkportal.cisstaging.com/signup">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <Fade right>
              <div className="hero-image-area">
                <img
                  src={
                    config.imageURL + "public/upload/cms/widget/" + hero?.image
                  }
                  className="mx-auto d-block"
                />
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      ;{/*============= HERO SECTION  END   ==============*/}
    </>
  );
};

export default Hero;
