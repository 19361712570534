import axios from "axios";
import { FC, useEffect, useState } from "react";
import config from "../utils/config";
// @ts-ignore
import Fade from "react-reveal/Fade"
// @ts-ignore
import DOMPurify from "dompurify";
import Loader from "../components/Loader"
export interface wwdIntro {
  image: string;
  name: string;
  body: string;
  title: string;
  keyword: string;
  id: any;
}

export interface AllPage {
  className?: string;
}
declare global {
  interface Window {
    marked: any;
  }
}
const FractionalArea: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [wwdTitle, setWwdTitle] = useState<wwdIntro>();
  const [wwdIntroOne, setWwdIntroOne] = useState<wwdIntro>();
  const [wwdIntroTwo, setWwdIntroTwo] = useState<wwdIntro>();
    const [loading, setLoading] = useState(false);
  const sectionWwd = () => {
      setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Home")
      .then(function (response) {
        const allData = response.data.data;
        console.log("wwd ", allData[3]);
        setWwdTitle(allData[2]);
        setWwdIntroOne(allData[3]);
        setWwdIntroTwo(allData[4]);
          setLoading(false);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    sectionWwd();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };
  return loading ? <Loader height={900} /> :(
    <>
      {/*=============  Fractional area    ==============*/}
       <section id="what_link" className=" fractional-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fractional-title-area">
                <h2>   <span dangerouslySetInnerHTML={createMarkup(wwdTitle?.name)}  />  </h2>
              </div>
              <div className="fractional-area-info">
                  <Fade left duration={6100}>
                 <div className="fractional-item common-para-type-two m-2">
                   <h5 className="">  <span  dangerouslySetInnerHTML={createMarkup(  wwdIntroOne?.name )} /> </h5>
                   <p className="text-justify ">   <span  dangerouslySetInnerHTML={createMarkup(   wwdIntroOne?.body  )} /> </p>
                 </div>
              </Fade>
                  <Fade left duration={3050}>
                <div className="fractional-item common-para-type-two m-2">
                   <h5 className="">  <span  dangerouslySetInnerHTML={createMarkup(  wwdIntroTwo?.name )} /> </h5>
                   <p className="text-justify "> <span  dangerouslySetInnerHTML={createMarkup(   wwdIntroTwo?.body  )} />  </p>
                 </div>
                  </Fade>


                <div className="fractional-item">
                  <div className="fractional-image-area">
                    <img
                        src={
                            config.imageURL +
                            "public/upload/cms/widget/" +
                            wwdTitle?.image
                        }
                        alt="image"
                        className="img-fluid"
                    />
                  </div>
                 </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      {/*=============  Fractional area END   ==============*/}
    </>
  );
};

export default FractionalArea;
