import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Home from "./containers/Home"
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Support from "./components/Support";

function App() {

    return (
        <Router>
            <Navbar/>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/terms-and-condition' component={TermsAndConditions} />
                <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                <Route exact path='/support' component={Support}/>
            </Switch>
            <Footer/>
        </Router>
    );
}

export default App;


