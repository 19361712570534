// import Fade from "../components/Tabs/CarouselArea";
import FractionalArea from "../components/FractionalArea";
import Hero from "../components/Hero";
import HowWeDo from "../components/HowWeDo";
import IntroIPO from "../components/IntroIPO";
import ProductsArea from "../components/ProductsArea";
import Support from "../components/Support";
import Tabs from "../components/Tabs/Tabs"
import NewCarousel from "../components/NewCarousel";


const Home = () => {
  return (
    <>
      <Hero />
      <IntroIPO />
      <FractionalArea />
      <NewCarousel/>
      <HowWeDo />
      <ProductsArea />
      <Support />
    </>
  );
};

export default Home;
